.home-card-img-horiz {
  width: 35vw;
  padding: 0px 20px 20px 20px;
}

.home-card-img-vert {
  width: 27vw;
  padding: 0px 20px 20px 20px;
}

.skewedshadow:hover {
  transform:  rotate(1.5deg) translateX(10px) translateY(25px) skewX(4deg) skewY(-4deg);
}


@media screen and (min-width: 320px) and (max-width: 675px){
  .home-card-img-horiz {
    width: 75vw;
  }
  
  .home-card-img-vert {
    width: 75vw;
  }
}
