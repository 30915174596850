.card-img-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card-img-horiz {
  width: 35vw;
  padding: 0px 20px 20px 0px;
  max-width: 100%;
}

.card-img-vert {
  width: 27vw;
  padding: 0px 20px 20px 0px;
  max-width: 100%;
}

.card-img-name-text {
  font-size: 3vh;
  text-align: center;
  text-decoration: underline
}

.card-img-name-info {
  font-size: 2.5vh;
  text-align: center;
  min-height: 3vh;
}


@media screen and (min-width: 320px) and (max-width: 675px){
  .card-img-horiz {
    width: 75vw;
    padding: 2vw;
  }
  
  .card-img-vert {
    width: 75vw;
    padding: 2vw
  }
}