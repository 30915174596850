.delete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30vh;
}

.delete-name-text {
  font-size: 5vh;
  padding: 2vh;
}

.delete-text {
  font-size: 4vh;
  padding: 2vh;
}

.delete-button {
  width: 10vw;
}

.delete-btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 3vh;
}
