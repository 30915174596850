.loading-img-vert {
  width: 10vw;
  max-width: 100%;
}

.rotate {
  animation: rotation 3.5s infinite linear;
}

@media screen and (min-width: 320px) and (max-width: 675px){
  .loading-img-vert {
    width: 40vw;
    padding: 2vw
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}