.allcards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allcards-add-btn {
  display: flex;
  padding: 2vw;
  flex-direction: row;
  justify-content: space-around;
}

.allcards-cardwrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.allcards-wrapper {
  width: 45vw;
  display: flex;
  flex-direction: column;
  margin: 1vh;
  padding-bottom: 5vh;
}

.delete-card-btn {
  align-self: center;
}

@media screen and (min-width: 320px) and (max-width: 675px){
  .allcards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .allcards-add-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .allcards-cardwrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .allcards-wrapper {
    display: flex;
    width: 95vw;
    flex-direction: column;
    align-items: center;
  }

  .filter-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}