.filter-selected {
  color: red
}

.filter-not-selected {
  color: green
}

.filter-btn-wrapper {
  padding: 0vh 1vh;
}

.filters-container {
  display: flex;
}

@media screen and (min-width: 320px) and (max-width: 675px){
  .filters-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  button {
    width: 42vw;
    height: 10vw;
    margin: 1.5vw
  }
}