.cardadd-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cardadd-container div {
  padding: .6vw 0 0 0 
}

.cardadd-row {
  padding-left: 2vh;
  padding-right: 2vh;
}

.cardadd-label {
  padding: 0 1vh 0 0
}

.cardadd-text-input {
  width: 30vw;
  height: 3vh;
  font-size: 2vh
}

.cardadd-form-btn {
  margin: 2vh;
  width: 10%;
  height: 4vh
}

.go-back-btn {
  padding-left: 20vw;
}

@media screen and (min-width: 320px) and (max-width: 675px){
  .cardadd-container div {
    padding: 0.6vh
  }
  .loaded-image {
    width: 75vw;
  }

  .cardadd-form-btn {
    width: 42vw;
    height: 10vw;
    margin: 1.5vw
  }

  .cardadd-row {
    display: flex;
    flex-direction: column;
  }

  .cardadd-text-input {
    width: 80vw;
    height: 4vh;
    font-size: 2vh
  }

  .cardadd-radio {
    zoom: 2
  }

  .checkbox {
    zoom: 2
  }
  label {
    font-size: 2.5vh
  }
  .checkboxes {
    justify-content: center;
  }

  .go-back-btn {
    display: flex;
    justify-content: center;
    padding: 0px
  }
}