.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload-message {
  display: flex;
  flex-direction: row;
  font-size: 9vh;
}

button {
  font-size: 15px;
}

@media screen and (min-width: 320px) and (max-width: 675px){
  .upload-message {
    font-size: 9vw;
  }
}